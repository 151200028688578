import React, { useEffect, useMemo, useState } from 'react'
import './Login.scss'
import { ReactComponent as LenzaIcon } from '../../assets/images/lenzaLogoWeb.svg'
import Heading from '../../shared/ui/Heading/Heading'
import { useLoginActions } from '../../state/slices/login'
import { requestCodeByEmail } from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { Button } from '@clout-team/web-components'
import Privacy from '../Privacy/Privacy'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import { useTranslation } from 'react-i18next'
import Input from '../../shared/ui/Input/Input'
import { isValidEmail } from '../../shared/lib/isValidEmail'

const LoginWeb = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const loginActions = useLoginActions()
  const error = useAppSelector((state) => state.login.error)
  const isLoading = useAppSelector((state) => state.login.is_loading)

  const [search, setSearch] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)

  const onChange = (e: any) => {
    const value = e.target.value.trim()
    if (isError) setIsError(false)
    setSearch(value)
  }

  const isValid: boolean = useMemo(() => {
    return isValidEmail(search)
  }, [search])

  const onBlur = () => {
    if (!isValid && search.length > 0) {
      setIsError(true)
    } else if (isError) {
      setIsError(false)
    }
  }

  const onSubmit = async () => {
    const searchValue = (document.querySelector<HTMLInputElement>('#email-input')?.value ?? search).trim();
    if (isValid && !isError && searchValue.length > 0) {
      loginActions.setIsLoading(true)
      loginActions.setMail(searchValue)
      dispatch(requestCodeByEmail({ email: searchValue }))
    }
  }

  const onKeyDown = (e: any) => {
    if (e.code === 'Enter') {
      onSubmit()
    }
  }

  const onDismiss = () => {
    setSearch('')
    setIsError(false)
  }

  useEffect(() => {
    const input = document.querySelector('.log_login__input') as HTMLInputElement

    let timer = setTimeout(() => {
      if (input) input.focus()
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  const disabledSave = isError || search.length === 0 || !isValid

  return (
    <div className={'log_login'}>
      <LenzaIcon className={'log_login__logo'} />
      <Heading
        className='log_login_header'
        title={t('auth.new.welcome')}
        description={t('auth.new.main.description')}
      />

      <div className={'log_login__bottom_container'}>
        <Input
          id={'email-input'}
          title={t('auth.new.main.email_address')}
          className={'log_login__input'}
          fullWidth
          capitalizeValue={false}
          onDismissClick={onDismiss}
          placeholder={t('auth.new.main.enter_email')}
          value={search}
          errorMessage={isError ? t('auth.new.main.error') : ''}
          onChange={onChange}
          onBlurHandler={onBlur}
          onKeyDown={onKeyDown}
          type={'email'}
          focus={true}
        />

        {error !== null && <p className={'log_login__error'}>{t(error)}</p>}

        <Button
          loading={isLoading}
          size={'lg'}
          disabled={disabledSave}
          fullWidth
          onClick={onSubmit}
        >
          {t('auth.new.main.continue_btn')}
        </Button>
      </div>
      <Privacy />
    </div>
  )
}

export default LoginWeb
